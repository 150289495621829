<template>
	<div class="row offers mt-2">
		<b-col lg="12">
			<div class="card">
				<b-row align-h="center">
					<b-col lg="6">
						<div class="p-2">
							<div class="mt-2 text-center">Search Bundle Offers</div>
							<b-input-group class="mt-3">
								<b-form-input
									v-model="query_search"
									type="search"
									placeholder="Type to search"
									@change="setQuerySearch"
									v-on:keyup.enter="asyncFind"
									trim
								></b-form-input>
								<b-input-group-append>
									<b-button type="submit" variant="primary" @click="asyncFind">Search</b-button>
									<b-button type="button" variant="dark" class="ml-2" @click="asyncClearFind">Clear</b-button>
									<b-spinner v-show="isLoadingSearch" variant="dark" class="ml-2" label="Loading .."></b-spinner>
								</b-input-group-append>
							</b-input-group>
						</div>
					</b-col>
				</b-row>
				<div class="card-body">
					<div class="row justify-content-end pb-1">
						<span class="pr-2">
							<router-link class="btn btn-sm btn-dark" :to="{name: BundleCreatePath.name}">
                <em class="fa fa-plus"></em>
              </router-link>
						</span>
						<span>
							<button class="btn btn-sm btn-dark" @click="actionGetOffers">
								<em class="fa fa-refresh"></em>
							</button>
						</span>
						<label for="" class="col-sm-auto">Count </label>
						<strong class="col-sm-auto">{{ totalRows }}</strong>
					</div>
					<b-table
						sticky-header="500px"
						:items="items"
						:fields="fields"
						:busy="isLoading"
						:isLoading="isLoading"
						show-empty
						responsive="xl"
						class="mt-2"
						hover
					>
						<template v-slot:table-busy>
							<div class="text-center text-dark my-2">
								<b-spinner
									class="align-middle"
									variant="dark"
									type="grow"
									label="Loading .."
								>
								</b-spinner>
								Loading ..
							</div>
						</template>
						<template #cell(No)="row">
							{{ (currentPage - 1) * perPage + (row.index + 1) }}
						</template>
						<template #cell(price_idr)="row">
							{{ formatPrice(row.item.price_idr, "IDR") }}
						</template>
						<template #cell(price_usd)="row">
							{{ formatPrice(row.item.price_usd, "USD") }}
						</template>
						<template #cell(is_free)="row">
							<div>
								<span v-if="row.item.is_free" class="badge badge-success">
									Y
								</span>
								<span v-else class="badge badge-danger"> N </span>
							</div>
						</template>
						<template #cell(offer_status)="row">
							{{ OfferStatus(row.item.offer_status) }}
						</template>
						<template #cell(is_active)="row">
							<div>
								<span v-if="row.item.is_active" class="badge badge-success">
									Active
								</span>
								<span v-else class="badge badge-danger">
									Inactive
								</span>
							</div>
						</template>
						<template #cell(action_edit)="row">
							<div class="text-right">
								<router-link class="btn btn-sm btn-default" :to="{name: BundleEditPath.name, params: { id: row.item.id }}">
									<em class="fa fa-edit"></em>
								</router-link>
							</div>
						</template>
					</b-table>
				</div>
				<b-row v-if="(totalRows/perPage) > 1" class="justify-content-start ml-1 mt-2 pt-4">
					<b-col lg="3">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							aria-controls="my-table"
						/>
					</b-col>
				</b-row>
			</div>
		</b-col>
	</div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { BundleCreatePath, BundleEditPath } from "../../router/marketing";
export default {
	name: "offer-bundle",
	data() {
		return {
			BundleCreatePath,
			BundleEditPath,
			currentPage: 1,
			perPage: 100,
			filter: {},
			items_search: [],
			query_search: '',
			isLoadingSearch: false,
			fields: [
				{ key: "No", label: "No" },
				{
					key: "name",
					label: "Name",
				},
				{ key: "offer_code", label: "Offer Code" },
				{
					key: "price_idr",
					label: "Price IDR",
					thClass: "text-right",
					tdClass: "text-right",
				},
				{
					key: "price_usd",
					label: "Price USD",
					thClass: "text-right",
					tdClass: "text-right",
				},
				{
					key: "is_free",
					label: "Is Free",
					thClass: "text-center",
					tdClass: "text-center",
				},
				{ key: "offer_status", label: "Offer Status" },
				{ key: "is_active", label: "Active" },
				{
					key: "created",
					label: "Created",
					formatter: function(value) {
						return moment(value).format("DD-MM-YYYY");
					},
				},
				{ key: "action_edit", label: "Edit" },
			],
		};
	},
	computed: {
		...mapState({
			isLoading: (state) => state.offersBundle.isLoading,
			items: (state) => state.offersBundle.items,
			isError: (state) => state.offeoffersBundlers.isError,
			totalRows: (state) => state.offersBundle.totalRows,
		}),
	},
	mounted() {
		this.actionGetOffers();
	},
	watch: {
		currentPage: function() {
			this.actionGetOffers();
		},
	},
	methods: {
		...mapActions("offersBundle", ["fetchOffers", "searchOffers"]),
		actionGetOffers() {
			if (this.query_search == "") {
				let payload = {
					page: this.currentPage,
					limit: this.perPage,
				};
				this.fetchOffers(payload);
			} else {
				this.asyncFind();
			}
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
		setQuerySearch(query) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.query_search = query;
			}, 1000);
		},
		formatDate(tgl) {
			if (tgl) {
				return moment(tgl).format("DD MMM YY hh:mm");
			}
			return "-";
		},
		formatDateValid(from, to) {
			return (
				moment(from).format("DD MMM YY") +
				" - " +
				moment(to).format("DD MMM YY")
			);
		},
		OfferStatus(key) {
			if (key == 1) {
				return "New";
			} else if (key == 2) {
				return "Waitting For Review";
			} else if (key == 3) {
				return "In Review";
			} else if (key == 4) {
				return "Rejected";
			} else if (key == 5) {
				return "Approved";
			} else if (key == 6) {
				return "Prepare for sale";
			} else if (key == 7) {
				return "Ready For Sale";
			} else if (key == 8) {
				return "Not For Sale";
			}
		},
		asyncFind() {
			this.isLoadingSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				const query = this.query_search;
				if (!query) {
					this.actionGetOffers();
					this.isLoadingSearch = false;
					return;
				}
				this.searchOffers({
					q: query,
					page: this.currentPage,
					limit: this.perPage,
				})
					.then((response) => {
						this.items = response.data.data.rows;
						this.isLoadingSearch = false;
					})
					.catch(() => {
						this.isLoadingSearch = false;
					});
			}, 1000);
		},
		asyncClearFind() {
			this.query_search = "";
			this.actionGetOffers();
			this.isLoadingSearch = false;
		},
		formatPrice(value, currency) {
			if (value) {
				let formatter = null;
				if (currency == "USD") {
					formatter = new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: currency,
					});
				} else {
					formatter = new Intl.NumberFormat("en-ID", {
						style: "currency",
						currency: currency,
					});
				}

				return formatter.format(value);
			}

			return "0";
		},
	},
};
</script>
